import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";

import { Logo } from "../../UIElements/logo/logo.component";
import { renderWhenTrue, renderWhenTrueOtherwise } from "../../helpers/rendering";
import { Theme } from "../../appConfig";
import { colors } from "../../UIGlobals";
import { Icon } from "../../UIElements";

import { LanguageCode, SidebarTheme } from "./loginWrapper.constants";
import {
  Sidebar,
  LanguageIconWrapper,
  LogoWrapper,
  Inner,
} from "./loginWrapper.styled";

const LoginWrapper = ({ children, loggedIn, i18n }) => {
  const handleChangeLanguage = useCallback(() => {
    i18n.changeLanguage(i18n.language === LanguageCode.EN ? LanguageCode.PL : LanguageCode.EN);
  }, [i18n]);

  const renderPLIcon = renderWhenTrue(() => (
    <Icon icon="pl" size={26} color={colors().red} />
  ));

  const renderENIcon = renderWhenTrue(() => (
    <Icon icon="en" size={26} borderRadius="50%" />
  ));

  const renderEmptySidebar = () => <Sidebar $loggedOut={!loggedIn} />;

  const renderLogoSidebar = () => (
    <Sidebar $loggedOut={!loggedIn}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <LanguageIconWrapper onClick={handleChangeLanguage}>
        {renderPLIcon(i18n.language === LanguageCode.EN)}
        {renderENIcon(i18n.language !== LanguageCode.EN)}
      </LanguageIconWrapper>
    </Sidebar>
  );

  const renderSidebar = renderWhenTrueOtherwise(
    renderLogoSidebar,
    renderEmptySidebar,
  );

  return (
    <div>
      {!Theme.ModernNavigation && renderSidebar(Theme.Sidebar === SidebarTheme.WithIcon)}
      <Inner>{children}</Inner>
    </div>
  );
}

export default withTranslation()(LoginWrapper);
